@use 'libs/styles/variables';
@use 'libs/styles/layout';

.container {
  @include layout.multi-column($gap: 58px, $count: 6);
  @include layout.max-width;
  position: relative;
  align-items: flex-start;
  height: 925px;
  padding-top: 64px;

  @media (max-width: variables.$device-lg) {
    padding-top: 106px;
  }

  & * {
    position: relative;
    z-index: 3;
  }

  h2 {
    @include variables.header(1);
    margin-bottom: 34px;
    font-weight: 700;
    font-family: 'GT Haptik', sans-serif;
    line-height: 0.95;
    color: variables.$color-dark-light;

    @media (max-width: variables.$device-md) {
      margin-bottom: 8px;
    }
  }

  & > :first-child {
    @include layout.column;
    position: relative;
    flex: 2;
    align-items: flex-start;
    max-width: 280px;
    padding-top: 30px;

    & > :first-child {
      margin-bottom: 12px;
    }

    .examples-button {
      margin-top: 12px;
      font-size: 17px;
      box-shadow: variables.$box-shadow-medium-orange;
    }

    @media (max-width: variables.$device-lg) {
      max-width: 320px;
      padding-top: 0;
    }
  }

  & > :nth-child(2) {
    position: relative;
    flex: 4;
    max-width: 560px;

    @media (max-width: variables.$device-md) {
      display: none;
    }
  }

  @media (max-width: variables.$device-md) {
    @include layout.column;
    align-items: center;
    height: 580px;
    padding-top: 40px;
  }
}

.actions {
  @include layout.row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 18px;
  margin-left: -2px;

  @media (max-width: variables.$device-md) {
    justify-content: flex-start;
    margin-top: 8px;
    margin-bottom: 24px;
    margin-left: -2px;
  }
}

.icon-row {
  @include layout.row;
  z-index: 5;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 40px;
  padding-right: 10%;
  padding-left: 14%;
  filter: variables.$token-shadow;
  will-change: filter;

  @media (max-width: variables.$device-lg) {
    padding-right: 14%;
    padding-left: 10%;
  }
}

.list {
  @include layout.column(0);
  position: relative;
  align-items: center;
  padding: 24px;
  list-style: none;

  @media (max-width: variables.$device-md) {
    margin-bottom: 48px;
  }

  & > svg {
    path {
      animation: variables.$dash-animation--reverse;
    }

    &:last-of-type {
      transform: scaleX(-1);
    }
  }

  & > li {
    @include layout.column;
    padding: 30px;
    padding-right: 42px;
    box-shadow: none;

    h3 {
      @include variables.header(2);
      font-weight: 700;
      font-family: 'GT Haptik', sans-serif;
      color: variables.$color-dark-light;
    }

    p {
      @include variables.paragraph;
      color: variables.$color-disabled;
    }

    img {
      position: absolute;
      right: -30px;
      object-fit: contain;
      filter: variables.$token-shadow;
      will-change: filter;

      @media (max-width: variables.$device-md) {
        right: -20px;
        width: 50px;
      }
    }
  }
}

.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (max-width: variables.$device-md) {
    height: unset;
  }
}

.lines-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: variables.$device-md) {
    display: none;
  }

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 947px;
    object-fit: cover;
    transform: translateX(-50%);

    path {
      animation: variables.$dash-animation;
    }
  }
}

.gumball {
  position: absolute;
  top: 400px;
  left: 50%;
  object-fit: cover;
  transform: translateX(-50%);
  filter: variables.$token-shadow;
  will-change: filter;

  img {
    object-fit: contain;
  }

  @media (min-width: variables.$device-lg) {
    top: 460px;
  }

  @media (max-width: variables.$device-md) {
    display: none;
  }
}

.mobile-only {
  display: none;

  @media (max-width: variables.$device-md) {
    display: block;
  }
}

.components-section {
  @include layout.row;
  position: relative;
  border-top: 1px solid variables.$color-lighter;
  padding: 4rem 8rem;
  background: rgba(255, 255, 255, 90%);

  @media (max-width: variables.$device-md) {
    padding: 4rem 2rem;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;

    &.donation-modal-shot {
      transform: translate(-700px, -40px) rotate(-15deg);

      @media (max-width: variables.$device-md) {
        transform: translate(-480px, 40px) rotate(15deg);
      }
    }

    &.search-modal-shot {
      transform: translate(300px, -180px) rotate(15deg);

      @media (max-width: variables.$device-md) {
        transform: translate(120px, -260px) rotate(15deg);
      }
    }

    &.donate-org-button-shot {
      transform: translate(300px, 50px) rotate(-3deg);

      @media (max-width: variables.$device-md) {
        display: none;
      }
    }

    &.donate-fund-button-shot {
      transform: translate(-700px, -100px) rotate(5deg);

      @media (max-width: variables.$device-md) {
        display: none;
      }
    }
  }

  div {
    @include layout.column;
    align-items: center;
    max-width: 40rem;

    &:first-child {
      font-weight: 700;
    }

    p {
      @include variables.paragraph(true);
      margin-bottom: 1rem;
      text-align: center;
      color: variables.$color-disabled;
    }

    h2 {
      @include variables.header(1);
      font-weight: 600;
      font-family: 'GT Haptik', sans-serif;
      text-align: center;
    }
  }
}
