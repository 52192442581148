@use 'libs/styles/variables';
@use 'libs/styles/layout';

.wrapper {
  border-top: 1px solid variables.$color-lighter;
  border-bottom: 1px solid variables.$color-lighter;
  background-color: variables.$color-near-white;
}

.container {
  @include layout.max-width(true);
  @include layout.column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;

  .computer {
    margin-top: 24px;
    margin-bottom: 12px;
    filter: variables.$token-shadow;
    will-change: filter;
  }

  h2 {
    @include variables.header(1);
    font-weight: 700;
    font-family: 'GT Haptik', sans-serif;
    color: variables.$color-dark-light;
  }

  & > p {
    @include variables.paragraph;
    max-width: 450px;
    margin-top: 0;
    margin-bottom: 8px;
    text-align: center;
    color: variables.$color-disabled;
  }

  .try-button {
    margin-bottom: 8px;
    font-size: 17px;
    box-shadow: variables.$box-shadow-medium-purple;
  }
}

.list {
  @include layout.multi-column(20px, $count: 3);
  align-items: stretch;
  padding-bottom: 30px;
  list-style: none;

  @media (max-width: variables.$device-md) {
    @include layout.column(20px);
    align-items: stretch;
  }

  & > li {
    @include layout.column;
    position: relative;
    justify-content: flex-start;

    &.feature-card__coming-soon {
      @media (max-width: variables.$device-md) {
        margin-bottom: 12px;
      }
    }

    h4 {
      @include variables.header(3);
      font-weight: 700;
      font-family: 'GT Haptik', sans-serif;
      color: variables.$color-dark-light;
    }

    p {
      @include variables.paragraph;
      color: variables.$color-disabled;
    }
  }
}

.coming-soon {
  position: absolute;
  bottom: -12px;
  left: 50%;
  min-width: 120px;
  transform: translateX(-50%);

  @media (max-width: variables.$device-md) {
    min-width: 104px;
  }
}

.links {
  @include layout.row;
  margin-bottom: 36px;
}
